<template>
	<div class="layer-popup gray">
      	<div class="pop-wrap">
			<div class="pop-inner">
				<div class="pop-head">
					<button type="button" class="close-btn" @click="close()"></button>
				</div>
				<div class="pop-cont">
					<div class="txt-con">
						<div class="scroll-area">
							<div class="contact-area">
								<div class="contact-btn-wrap">
									<div href="javascript:" class="contact-btn-area" v-if="!isEmpty(invitation.invitationContactGroomNumber)">
										<p class="groom-bride">신랑</p>
										<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
										<div href="javascript:" class="contact-btn-sub-wrap">
											<a :href="'tel:'+invitation.invitationContactGroomNumber"><img :src="`${publishpath}pub/images/contact-phone.png`" alt=""></a>
											<a :href="'sms:'+invitation.invitationContactGroomNumber"><img :src="`${publishpath}pub/images/contact-sms.png`" alt=""></a>
										</div>
									</div>
									<div href="javascript:" class="contact-btn-area" v-if="!isEmpty(invitation.invitationContactBrideNumber)">
										<p class="groom-bride">신부</p>
										<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
										<div href="javascript:" class="contact-btn-sub-wrap">
											<a :href="'tel:'+invitation.invitationContactBrideNumber"><img :src="`${publishpath}pub/images/contact-phone.png`" alt=""></a>
											<a :href="'sms:'+invitation.invitationContactBrideNumber"><img :src="`${publishpath}pub/images/contact-sms.png`" alt=""></a>
										</div>
									</div>
								</div>
								
								<div class="contact-btn-wrap">
									<div href="javascript:" class="contact-btn-area"  >
										<template v-if="!isEmpty(invitation.invitationContactGroomFatherNumber)">
											<p class="groom-bride">신랑 {{invitation.invitationInfoGroomFatherRelationship}}</p>
											<p class="name">{{invitation.invitationInfoGroomFatherName}}</p>
											<div href="javascript:" class="contact-btn-sub-wrap">
												<a :href="'tel:'+invitation.invitationContactGroomFatherNumber"><img :src="`${publishpath}pub/images/contact-phone.png`" alt=""></a>
												<a :href="'sms:'+invitation.invitationContactGroomFatherNumber"><img :src="`${publishpath}pub/images/contact-sms.png`" alt=""></a>
											</div>
										</template>
									</div>
									<div href="javascript:" class="contact-btn-area"  >
										<template v-if="!isEmpty(invitation.invitationContactBrideFatherNumber)">
											<p class="groom-bride">신부 {{invitation.invitationInfoBrideFatherRelationship}}</p>
											<p class="name">{{invitation.invitationInfoBrideFatherName}}</p>
											<div href="javascript:" class="contact-btn-sub-wrap">
												<a :href="'tel:'+invitation.invitationContactBrideFatherNumber"><img :src="`${publishpath}pub/images/contact-phone.png`" alt=""></a>
												<a :href="'sms:'+invitation.invitationContactBrideFatherNumber"><img :src="`${publishpath}pub/images/contact-sms.png`" alt=""></a>
											</div>
										</template>
									</div>
								</div>
								
								<div class="contact-btn-wrap">
									<div href="javascript:" class="contact-btn-area" >
										<template v-if="!isEmpty(invitation.invitationContactGroomMotherNumber)">
											<p class="groom-bride">신랑 {{invitation.invitationInfoGroomMotherRelationship}}</p>
											<p class="name">{{invitation.invitationInfoGroomMotherName}}</p>
											<div href="javascript:" class="contact-btn-sub-wrap">
												<a :href="'tel:'+invitation.invitationContactGroomMotherNumber"><img :src="`${publishpath}pub/images/contact-phone.png`" alt=""></a>
												<a :href="'sms:'+invitation.invitationContactGroomMotherNumber"><img :src="`${publishpath}pub/images/contact-sms.png`" alt=""></a>
											</div>
										</template>
									</div>
									<div href="javascript:" class="contact-btn-area" >
										<template v-if="!isEmpty(invitation.invitationContactBrideMotherNumber)">
											<p class="groom-bride">신부 {{invitation.invitationInfoBrideMotherRelationship}}</p>
											<p class="name">{{invitation.invitationInfoBrideMotherName}}</p>
											<div href="javascript:" class="contact-btn-sub-wrap">
												<a :href="'tel:'+invitation.invitationContactBrideMotherNumber"><img :src="`${publishpath}pub/images/contact-phone.png`" alt=""></a>
												<a :href="'sms:'+invitation.invitationContactBrideMotherNumber"><img :src="`${publishpath}pub/images/contact-sms.png`" alt=""></a>
											</div>
										</template>
									</div>
								</div>
								
								
							</div>
						</div>
					</div>
				</div>
			</div>
      	</div>
    </div>
</template>


<script>

import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'ContactPop',
  props:[],
  components: {},
  computed:{
  },
  data(){
    return {
										
		invitation:{}

										
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
		if(param.flag !== 'contactPop') return;
		if(param.data!==null && param.data!==undefined)
			context.invitation = param.data
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  methods: {
	close(){
    	EventBus.$emit('popupClose')
	},
	popupSave(){
		
    	EventBus.$emit('popupClose')
	}
  },

}
</script>
