<template>
    <div class="layer-popup gray">
      <div class="pop-wrap">
				<div class="pop-inner">
					<div class="pop-head">
						<button type="button" class="close-btn" @click="close"></button>
					</div>
					<div class="pop-cont">
						<div class="txt-con">
							<div class="scroll-area">
								<div class="attend-supply-area">
									<p class="sub-tit">신랑&신부에게 전달될 정보를 입력해 주세요.</p>
									<div class="input-wrap">
										<div class="input-div">
											<p class="tit essential">참석자 성함</p>
											<div class="form-input">
                        <CustomInput v-model="invitationAttend.invitationAttendName" :placeholder="'대표자 한 분의 성함 입력'"/>
											</div>
										</div>
										<div class="input-div" v-if="invitation.invitationAttendManCountYn === 'Y' && invitationAttend.invitationAttendPossibilityYn === 'Y'">
											<p class="tit essential">동행인 수</p>
											<div class="form-select">
												<select id="" title="" v-model="invitationAttend.invitationAttendCompanionCount">
													<option value="0">본인을 제외한 인원수</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
													<option value="11">11</option>
													<option value="12">12</option>
													<option value="13">13</option>
													<option value="14">14</option>
													<option value="15">15</option>
													<option value="16">16</option>
													<option value="17">17</option>
													<option value="18">18</option>
													<option value="19">19</option>
													<option value="20">20</option>
													<option value="21">21</option>
													<option value="22">22</option>
													<option value="23">23</option>
													<option value="24">24</option>
													<option value="25">25</option>
													<option value="26">26</option>
													<option value="27">27</option>
													<option value="28">28</option>
													<option value="29">29</option>
													<option value="30">30</option>
												</select>
											</div>
										</div>
										<div class="input-div" v-if="invitation.invitationAttendNumberYn === 'Y'">
											<p class="tit">{{invitationAttend.invitationAttendPossibilityYn === 'Y'?'참석자':'응답자'}} 연락처</p>
											<div class="form-input">
                        <CustomInput v-model="invitationAttend.invitationAttendNumber" :placeholder="'대표자 한 분의 연락처 입력'"/>
											</div>
										</div>
										<div class="input-div"  v-if="invitation.invitationAttendEatYn === 'Y' && invitationAttend.invitationAttendPossibilityYn === 'Y'">
											<p class="tit essential">식사 여부</p>
											<div class="choice-btn-wrap">
												<a href="javascript:" @click="invitationAttend.invitationAttendEatYn = 'Y'" :class="['choice-btn',invitationAttend.invitationAttendEatYn === 'Y'?'on':'']"><span>식사함</span></a>
												<a href="javascript:" @click="invitationAttend.invitationAttendEatYn = 'N'" :class="['choice-btn',invitationAttend.invitationAttendEatYn === 'N'?'on':'']"><span>식사 안함</span></a>
											</div>
										</div>
										<div class="input-div" v-if="invitation.invitationAttendMessageYn === 'Y'">
											<p class="tit">전달 메시지</p>
											<div class="form-input">
                        <CustomInput v-model="invitationAttend.invitationAttendMessage" :placeholder="'최대 25자 입력 가능'"/>
											</div>
										</div>
									</div>
									<div class="agree-wrap">
										<p class="tit">개인정보 수집 및 이용동의(필수)</p>
										<p class="txt">참석여부 전달을 위한 개인정보 수집 및 이용에 동의해 주세요.<br />항목 : 성함, 연락처 / 보유기간 : 청첩장 이용 종료시 까지</p>
										<div class="form-checkbox">
											<input type="checkbox" id="agree1" name=""  @change="changeAgreementYn" :checked="agreementYn==='Y'">
											<label for="agree1">개인정보 수집에 동의합니다.</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="btn-wrap">
						<a href="javascript:"  @click="!validataionCheck?null:clickAttend()" :class="['black-btn', !validataionCheck ?'disabled':'']"><span>제출하기</span></a>
					</div>
				</div>
				
      </div>
    </div>
</template>


<script>
import CustomInput from "@/components/common/CustomInput.vue";
import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'PostPop',
  props:['real'],
  components: {CustomInput},
  computed:{
    validataionCheck(){
      
      if(this.invitationAttend.invitationAttendPossibilityYn === 'Y'){
        if(this.isEmpty(this.invitationAttend.invitationAttendName))
          return false;


        if(this.invitation.invitationAttendEatYn === 'Y' && this.isEmpty(this.invitationAttend.invitationAttendEatYn))
          return false;
      }else{
        if(this.isEmpty(this.invitationAttend.invitationAttendName))
          return false;

      }

      if(this.agreementYn === 'N')return false;

      return true;
    }
  },
  data(){
    return {
      invitationAttend: {
        invitationAttendId: null, // id
        invitationAttendGroomBride: null,  // 신랑신부선택
        invitationAttendPossibilityYn: null,  // 참석가능유무
        invitationAttendName: null,  // 참석자명
        invitationAttendCompanionCount: 0,  // 동행인수
        invitationAttendNumber: null,  // 참석자연락처
        invitationAttendMessage: null, // 참석자메세지
      }		,
      invitation:{}	,
      invitationAttend:{},

      agreementYn: 'N'
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
		  if(param.flag !== 'attendPop4') return;
      if(param.invitationAttend!==null && param.invitationAttend!==undefined){
		    context.invitationAttend = param.invitationAttend;
        context.invitation = param.invitation;

      }
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  methods: {
    close(){
        EventBus.$emit('popupClose')
    },
    clickAttend(){
      if(this.real){
        this.invitationAttend.invitationId = this.invitation.invitationId
        const parameter = {
          invitationAttend:this.invitationAttend
        }
        this.$store.dispatch('REQUEST_INSERT_INVITATION_ATTEND',parameter)
        
      }else{
        EventBus.$emit('popupClose')

      }
      
    },
    changeAgreementYn(e){
      if(e.target.checked){
        this.agreementYn = 'Y'
      }else{

        this.agreementYn = 'N'
      }
    }
  },

}
</script>
