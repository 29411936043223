<template>
  <div class="layer-popup gray">
    <div class="pop-wrap">
			<div class="pop-inner">
				<div class="pop-head">
					<button type="button" class="close-btn" @click="close"></button>
				</div>
				<div class="pop-cont" v-if="invitation!==null">
					<div class="txt-con">
						<div class="scroll-area">
							<div class="attend-supply-area">
								<div class="tit-wrap">
									<p class="tit">참석 여부 전달하기</p>
									<p class="txt">결혼식 참석 전 참석여부를 응답해 주시면 <br />결혼식 준비에 큰 도움이 됩니다.</p>
								</div>
								<div class="info-wrap">
									<div class="info">
										<p class="icon"><img :src="`${publishpath}pub/images/icon-heart-gray.svg`" alt=""></p>
										<p class="txt">신랑 {{invitation.invitationInfoGroomLastName + invitation.invitationInfoGroomFirstName}} & 신부 {{invitation.invitationInfoBrideLastName + invitation.invitationInfoBrideFirstName}}</p>
									</div>
									<div class="info">
										<p class="icon"><img :src="`${publishpath}pub/images/icon-calendar-gray.svg`" alt=""></p>
										<p class="txt">{{getKorDatetime(invitation.invitationTimeDatetime)}}</p>
									</div>
									<div class="info">
										<p class="icon"><img :src="`${publishpath}pub/images/icon-location-gray.svg`" alt=""></p>
										<p class="txt">{{invitation.invitationAddressBuildingName + ' ' + invitation.invitationAddressBuildingFloor}}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="btn-wrap">
					<a href="javascript:" @click="clickAttendPopup2" class="black-btn"><span>참석 여부 전달하기</span></a>
				</div>
			</div>
    </div>
  </div>
</template>


<script>

import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'PostPop',
  props:[],
  components: {},
  computed:{
  },
  data(){
    return {
      invitation:null				
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
		if(param.flag !== 'attendPop1') return;
      context.invitation = param.invitation
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  methods: {
    close(){
        EventBus.$emit('popupClose')
    },
    clickAttendPopup2(){
      const param = {
        flag: 'attendPop2',
        invitation: this.invitation
      }
      EventBus.$emit('layerPopup',param)
      
    }
  },

}
</script>
