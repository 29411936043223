<template>
  <div class="layer-popup gray">
      <div class="pop-wrap">
				<div class="pop-inner">
					<div class="pop-head">
						<button type="button" @click="close" class="close-btn"></button>
					</div>
					<div class="pop-cont">
						<div class="txt-con">
							<div class="scroll-area">
								<div class="attend-supply-area">
									<p class="sub-tit">참석 여부를 선택해 주세요.</p>
									<div class="choice-btn-wrap">
										<a @click="invitationAttend.invitationAttendPossibilityYn = 'Y'" :class="['choice-btn',invitationAttend.invitationAttendPossibilityYn === 'Y'? 'on':'']"><span>참석 가능</span></a>
										<a @click="invitationAttend.invitationAttendPossibilityYn = 'N'" :class="['choice-btn',invitationAttend.invitationAttendPossibilityYn === 'N'? 'on':'']"><span>참석 불가</span></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="btn-wrap">
						<a href="javascript:" @click="invitationAttend.invitationAttendPossibilityYn === null?null:clickAttendPopup4()" :class="['black-btn', invitationAttend.invitationAttendPossibilityYn === null ?'disabled':'']"><span>다음 (2/3)</span></a>
					</div>
				</div>
      </div>
    </div>
</template>


<script>

import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'PostPop',
  props:[],
  components: {},
  computed:{
  },
  data(){
    return {
      invitationAttend: {
        invitationAttendId: null, // id
        invitationAttendGroomBride: null,  // 신랑신부선택
        invitationAttendPossibilityYn: null,  // 참석가능유무
        invitationAttendName: null,  // 참석자명
        invitationAttendCompanionCount: 0,  // 동행인수
        invitationAttendNumber: null,  // 참석자연락처
        invitationAttendMessage: null, // 참석자메세지
      },
      
		invitation: null,
    invitationAttend:{}				
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
		  if(param.flag !== 'attendPop3') return;
      if(param.invitationAttend!==null && param.invitationAttend!==undefined)
		    context.invitationAttend = param.invitationAttend;
    context.invitation = param.invitation;
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  methods: {
    close(){
        EventBus.$emit('popupClose')
    },
    clickAttendPopup4(){
      const param = {
        flag: 'attendPop4',
		    invitationAttend:this.invitationAttend,
        invitation: this.invitation
      }
      EventBus.$emit('layerPopup',param)
      
    }
  },

}
</script>
