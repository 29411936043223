<template>
    <div class="layer-popup gray">
      <div class="pop-wrap">
				<div class="pop-inner">
					<div class="pop-head">
						<p class="tit">방명록 삭제</p>
						<button type="button" class="close-btn" @click="close"></button>
					</div>
					<div class="pop-cont">
						<div class="txt-con">
							<div class="scroll-area">
								<div class="attend-supply-area">
									<div class="input-wrap">
										<div class="input-div" >
											<p class="tit essential">비밀번호</p>
											<div class="form-input">
                        <CustomInput v-model="invitationGuestBook.invitationGuestBookPassword" :placeholder="'방명록 삭제시 사용할 비밀번호'"/>
											</div>
										</div>
									</div>

                  

								</div>
							</div>
						</div>
					</div>
					<div class="btn-wrap">
						<a href="javascript:"  @click="!validataionCheck?null:clickGuestBook()" :class="['black-btn', !validataionCheck ?'disabled':'']"><span>삭제하기</span></a>
					</div>
				</div>
				
      </div>
    </div>
</template>


<script>
import CustomInput from "@/components/common/CustomInput.vue";

import EventBus from "@/utils/CommonEventBus";
const defaultInvitationGuestBook = () => {
  return {
    invitationGuestBookId : null,
	  invitationGuestBookName : null,
	  invitationGuestBookPassword : null,
	  invitationGuestBookContent : null,
	  invitationId : null,
  
	}
}
export default {
  name: 'GuestBookWritePop',
  props:['real'],
  components: {CustomInput,},
  computed:{
    validataionCheck(){
      if(this.isEmpty(this.invitationGuestBook.invitationGuestBookPassword)){
        return false;
      }

      return true;
    }
  },
  data(){
    return {
      invitationGuestBook: defaultInvitationGuestBook(),
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
		  if(param.flag !== 'guestBookDeletePop') return;
      context.invitationGuestBook = defaultInvitationGuestBook()
      if(param.invitationGuestBook!==null && param.invitationGuestBook!==undefined){
        context.invitationGuestBook = param.invitationGuestBook
        context.invitationGuestBook.invitationGuestBookPassword = ''
      }
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  methods: {
    close(){
      this.invitationGuestBook = defaultInvitationGuestBook();
      EventBus.$emit('popupClose')
    },
    clickGuestBook(){
      if(this.real){
        const parameter = {
          invitationGuestBook:this.invitationGuestBook,
			    context : this
        }
        this.$store.dispatch('REQUEST_DELETE_INVITATION_GUEST_BOOK',parameter)
        
      }else{
        EventBus.$emit('popupClose')

      }
      
    },
  },

}
</script>
