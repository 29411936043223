<template>
  <div class="layer-popup gray">
      <div class="pop-wrap">
				<div class="pop-inner">
					<div class="pop-head">
						<button type="button" @click="close" class="close-btn"></button>
					</div>
					<div class="pop-cont">
						<div class="txt-con">
							<div class="scroll-area">
								<div class="attend-supply-area">
									<p class="sub-tit">참석 여부를 전달하실 곳을 선택해 주세요.</p>
									<div class="choice-btn-wrap">
										<a @click="invitationAttend.invitationAttendGroomBride = 'groom'" :class="['choice-btn',invitationAttend.invitationAttendGroomBride === 'groom'? 'on':'']"><span>🤵🏻️신랑</span></a>
										<a @click="invitationAttend.invitationAttendGroomBride = 'bride'" :class="['choice-btn',invitationAttend.invitationAttendGroomBride === 'bride'? 'on':'']"><span>👰🏻‍♀️신부</span></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="btn-wrap">
						<a href="javascript:" @click="invitationAttend.invitationAttendGroomBride === null?null:clickAttendPopup3()" :class="['black-btn', invitationAttend.invitationAttendGroomBride === null ?'disabled':'']"><span>다음 (1/3)</span></a>
					</div>
				</div>
      </div>
    </div>
</template>


<script>

import EventBus from "@/utils/CommonEventBus";
const defaultInvitationAttend = () => {
  return {
		invitationAttendId: null, // id
		invitationAttendGroomBride: null,  // 신랑신부선택
		invitationAttendPossibilityYn: null,  // 참석가능유무
		invitationAttendEatYn: null, //식사여부
		invitationAttendName: null,  // 참석자명
		invitationAttendCompanionCount: '0',  // 동행인수
		invitationAttendNumber: null,  // 참석자연락처
		invitationAttendMessage: null, // 참석자메세지
		invitationId:null
	}
}
export default {
  name: 'PostPop',
  props:[],
  components: {},
  computed:{
  },
  data(){
    return {
		invitationAttend: defaultInvitationAttend(),
		invitation: null				
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
		if(param.flag !== 'attendPop2') return;
		context.invitationAttend = defaultInvitationAttend();
		context.invitation = param.invitation
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  methods: {
    close(){
        EventBus.$emit('popupClose')
    },
    clickAttendPopup3(){
      const param = {
        flag: 'attendPop3',
		invitationAttend:this.invitationAttend,
		invitation: this.invitation
      }
      EventBus.$emit('layerPopup',param)
      
    }
  },

}
</script>
