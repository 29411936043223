<template>
  	<div class="layer-popup">
      <div class="pop-wrap" style="width: auto;max-width:100%;max-height:80vh;width:100%">
				<div class="pop-inner" style="background: transparent;border-radius: 0px;">
					<div class="pop-head" style="border-bottom: 0px;padding: 0px;display: flex;float: right;">
						<p class="tit"></p>
						<button type="button" @click="close" class="white-btn" style="border: 0px;"></button>
					</div>
					<div class="pop-cont" style="background:transparent" >

            <swiper style="height:600px"
            v-if="!isEmpty(files) && files.length>0"
            :options="swiperOption"
            >
              <swiperSlide
                v-for="(file,index) in files"
                :key="index"
              >
						      <img :src=" eixstIdMakeDisplayUrl(file)" alt="" style="width: 100%;height: 100%;    object-fit: contain;">
              </swiperSlide>
            </swiper>
            
					</div>
				</div>
				
      </div>
    </div>
</template>


<script>

import EventBus from "@/utils/CommonEventBus";
// css의 경우, 전역으로 불러오시는 것이 편합니다
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'





// Import Swiper styles
export default {
  name: 'ImageExtendsViewPop',
  props:[],
  components: {
    swiper,
    swiperSlide
    },
  computed:{
  },
  data(){
    return {
      index : 0,
      files:[],
      swiperOption: {
        initialSlide:1,
        slidesPerView: 'auto',
        spaceBetween: 6, // swiper-slide 사이의 간격 지정
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        freeMode: false, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
      }
    }
  },
  
  created(){
    
  },
  mounted() {
	const context = this
    EventBus.$on("popupInit", (param) => {
      context.index = param.index
      context.swiperOption.initialSlide = context.index
      context.files = param.files
	});
  },
  beforeDestroy(){
    EventBus.$off('popupInit')
  },
  methods: {
    close(){
        this.index = 0
        this.files = []
        EventBus.$emit('popupClose')
    },
  },

}
</script>
